var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"transaction__filter--container py-2 pl-0 pr-2 justify-content-between",staticStyle:{"height":"82px","background-color":"#f6f7fa"}},[_c('b-button-group',_vm._l((_vm.listFilter),function(item,index){return (_vm.checkPermission(item.permission))?_c('b-button',{key:((item.name) + "-" + index),staticClass:"filter--button",class:{ active: item.name == _vm.filter },on:{"click":function($event){return _vm.navigation(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()}),1),_c('div',{staticClass:"d-flex search__export",staticStyle:{"width":"unset"}},[(
        _vm.comp == 'TablePayoutOther' &&
        _vm.checkPermission('tambah pembayaran lainnya')
      )?_c('b-button',{staticClass:"bg-white",on:{"click":function($event){return _vm.$bvModal.show('modal-payout-other')}}},[_vm._v(" Input Pembayaran Lainnya ")]):_vm._e()],1)],1),_c('keep-alive',[(
      _vm.checkPermission('list pembayaran ke supplier') &&
      _vm.comp == 'TablePayoutSupplier'
    )?_c('TablePayoutSupplier',{attrs:{"is-edit":_vm.is_edit}}):_vm._e(),(
      _vm.checkPermission('list pembayaran lainnya') &&
      _vm.comp == 'TablePayoutOther'
    )?_c('TablePayoutOther',{attrs:{"is-edit":_vm.is_edit}}):_vm._e(),(
      _vm.checkPermission('list pembayaran penjualan') &&
      _vm.comp == 'TableSalesReceipt'
    )?_c('TableSalesReceipt',{attrs:{"is-edit":_vm.is_edit}}):_vm._e(),_c(_vm.comp,{tag:"component",attrs:{"is-edit":_vm.is_edit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }